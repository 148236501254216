@import '../../common/sass/variables';

// override toastify basic styles, only use component styling
.Toastify {
  &__toast {
    min-height: 32px !important;
    padding: 0 !important;
    &-body {
      padding: 0 !important;
    }
    &-container {
      min-width: 300px;
      padding: 0px;
      width: 100%;
    }

    &--success {
      .Toastify__progress-bar {
        background: $success;
      }
    }
    &--info {
      .Toastify__progress-bar {
        background: $info;
      }
    }
    &--warning {
      .Toastify__progress-bar {
        background: $warning;
      }
    }
    &--error {
      .Toastify__progress-bar {
        background: $danger;
      }
    }
  }
}

@keyframes pullIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

.pullIn {
  animation-name: pullIn;
  animation-timing-function: cubic-bezier(0.17, 0.66, 0.08, 0.95);
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 0;
    transform: translateX(100%);
  }
}

.fadeOutRight {
  animation-name: fadeOutRight;
}

@media only screen and (max-width: 480px) {
  .Toastify {
    &__toast {
      margin-bottom: 5px !important;
      border-radius: var(--sp-2) !important;
      &-container {
        padding: 4px !important;
        left: auto !important;
        right: 0 !important;
        width: 100%;
        min-width: 100%;
      }
    }
  }
}


.alert {
  margin-bottom: 0 !important
}