@import './common/sass/variables';
@import '~react-toastify/dist/ReactToastify.css';
@import '@coreui/coreui/dist/css/coreui.min.css';
@import '@coreui/coreui/scss/coreui';

// Then add additional custom code here
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.wrapper {
  width: 100%;
  @include ltr-rtl('padding-left', var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding 0.15s);
}

.btn {
  max-height: 42px;
}

.popover {
  max-width: 400px;
}

.copyright {
  position: absolute;
  bottom: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
}
// Override Coreui theme
:root {
  --cui-blue: #0d6efd;
  --cui-indigo: #6610f2;
  --cui-purple: #6f42c1;
  --cui-pink: #d63384;
  --cui-red: #dc3545;
  --cui-orange: #fd7e14;
  --cui-yellow: #ffc107;
  --cui-green: #198754;
  --cui-teal: #20c997;
  --cui-cyan: #0dcaf0;
  --cui-black: #000015;
  --cui-white: #fff;
  --cui-gray: #9da5b1;
  --cui-gray-dark: #768192;
  --cui-gray-100: #f0f4f7;
  --cui-gray-200: #ebedef;
  --cui-gray-300: #d8dbe0;
  --cui-gray-400: #c4c9d0;
  --cui-gray-500: #b1b7c1;
  --cui-gray-600: #9da5b1;
  --cui-gray-700: #8a93a2;
  --cui-gray-800: #768192;
  --cui-gray-900: #636f83;
  --cui-primary: #39f;
  --cui-secondary: #ebedef;
  --cui-success: #51cc8a;
  --cui-info: #747af2;
  --cui-warning: #fc0;
  --cui-danger: #ef376e;
  --cui-light: #f0f4f7;
  --cui-dark: #636f83;
  --cui-primary-rgb: 51, 153, 255;
  --cui-secondary-rgb: 235, 237, 239;
  --cui-success-rgb: 81, 204, 138;
  --cui-info-rgb: 116, 122, 242;
  --cui-warning-rgb: 255, 204, 0;
  --cui-danger-rgb: 239, 55, 110;
  --cui-light-rgb: 240, 244, 247;
  --cui-dark-rgb: 99, 111, 131;
  --cui-white-rgb: 255, 255, 255;
  --cui-black-rgb: 0, 0, 21;
  --cui-body-color-rgb: 44, 56, 74;
  --cui-body-bg-rgb: 255, 255, 255;
  --cui-font-sans-serif: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', 'Noto Sans', 'Liberation Sans', Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --cui-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  --cui-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --cui-body-font-family: var(--cui-font-sans-serif);
  --cui-body-font-size: 0.9375rem;
  --cui-body-font-weight: 400;
  --cui-body-line-height: 1.5;
  --cui-body-color: rgba(44, 56, 74, 0.95);
  --cui-body-bg: #fff;
  --cui-border-width: 1px;
  --cui-border-style: solid;
  --cui-border-color: #ebedef;
  --cui-border-color-translucent: rgba(0, 0, 21, 0.0875);
  --cui-border-radius: 0.375rem;
  --cui-border-radius-sm: 0.25rem;
  --cui-border-radius-lg: 0.5rem;
  --cui-border-radius-xl: 1rem;
  --cui-border-radius-2xl: 2rem;
  --cui-border-radius-pill: 50rem;
  --cui-heading-color: unset;
  --cui-link-color: #39f;
  --cui-link-hover-color: #297acc;
  --cui-code-color: #d63384;
  --cui-highlight-bg: #fff3cd;
}

.btn-primary {
  --cui-btn-color: #fff;
  --cui-btn-bg: #39f;
  --cui-btn-border-color: #39f;
  --cui-btn-hover-color: #000015;
  --cui-btn-hover-bg: #52a8ff;
  --cui-btn-hover-border-color: #47a3ff;
  --cui-btn-focus-shadow-rgb: 43, 130, 220;
  --cui-btn-active-color: #000015;
  --cui-btn-active-bg: #5cadff;
  --cui-btn-active-border-color: #47a3ff;
  --cui-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 21, 0.125);
  --cui-btn-disabled-color: #000015;
  --cui-btn-disabled-bg: #39f;
  --cui-btn-disabled-border-color: #39f;
}

.btn-info {
  color: white;
}

.bg-dark-gradient {
  background-color: var(--cui-dark, #212333);
  background-image: linear-gradient(45deg, var(--cui-dark-start, #3c4b64) 0%, var(--cui-dark-stop, #212333 100%));
}

.card,
.dropdown-menu,
.footer,
.header,
.sidebar {
  box-shadow: rgb(50 50 93 / 2%) 0 2px 5px -1px, rgb(0 0 0 / 5%) 0 1px 3px -1px;
  border-bottom: none;
}

.bg-light {
  background-color: rgba(var(--cui-light-rgb), var(--cui-bg-opacity)) !important;
}
